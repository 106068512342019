const apimUrl = process.env.API_URL;
const baseUrl = process.env.API_URL + "/UserManagementApi";
const notificationUrl = process.env.API_URL + "/NotificationApi"
const sellerManagerUrl = process.env.API_URL + "/BusinessReview"
const adminApiBaseUrl = process.env.API_URL + "/adminservice"
const coPilotUrl = "https://func-ms-copilot-uat-01.azurewebsites.net/api"

export function checkMSSalesAccess() {
  return window.httpService
    .get(baseUrl + "/CheckMsSaleAccess")
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}

export function checkIfSellerManager() {
  return window.httpService
    .get(sellerManagerUrl + "/CheckIfSellerManager")
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}

export function getMenuItems() {
  return window.httpService
    .get(baseUrl + "/GetUserResources")
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}

export function getFiscalYears() {
  return window.httpService
    .get(baseUrl + "/GetFiscalYears")
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}

export function gethelpLinks() {
  return window.httpService
    .get(adminApiBaseUrl + "/GetHelpLinks")
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}


export function getNotifications() {
  return window.httpService
    .get(notificationUrl  + "/GetUserNotifications")
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}

export function getUserPrefApiUrl() {
  return window.urlInference.infer(`${apimUrl}/UserPreferenceAPI/`);
}

export function checkValidCoPilotUser() {
  return fetch(coPilotUrl + "/copilot/validuser", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + window.AuthProvider.token,
      "FiscalYear": window.httpService.fiscalYear
    },
  })
  .then(res => res.json())
  .catch(window.httpService.handleError);
}
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as CoPilotIconSvg } from '../../../assets/icon-copilot.svg';

export default function CoPilotIcon() {

  return (
    <SvgIcon 
        xmlns="http://www.w3.org/2000/svg"
        color="primary"
        component={CoPilotIconSvg}
        viewBox="0 0 48 48"
    />
  );
}
